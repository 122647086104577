import React, { useState, useCallback } from 'react';
import './Popup.css';
import Dropzone from 'react-dropzone-uploader';
import { storage } from './firebase';
import { ref, uploadBytes, deleteObject } from 'firebase/storage';
import Lottie from 'lottie-react';
import loadingAnimation from './loadingAnimation.json';
import { useAuth } from './AuthContext';

const Popup = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { logout } = useAuth();

  const handleChangeStatus = useCallback(({ meta, file }, status) => {
    if (status === 'done') {
      setFile(file);
      setError(null);
    }
  }, []);

  const extractData = useCallback(async () => {
    if (file) {
      setLoading(true);
      setError(null);
      const fileRef = ref(storage, `${file.name}`);
      await uploadBytes(fileRef, file);

      try {
        const response = await fetch(process.env.REACT_APP_PROCESS_PDF_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fileName: `${file.name}`,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        setData(result);

        await deleteObject(fileRef);
        console.log("File deleted from storage:", file.name);
      } catch (error) {
        console.error("Error processing file:", error);
        setError("Error processing file: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  }, [file]);

  const resetUpload = useCallback(() => {
    setFile(null);
    setData(null);
    setError(null);
    setLoading(false);
  }, []);

  const InputContent = () => (
    <div className="dropzone-input">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
        <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
      </svg>
      <p>Drag and drop a PDF file here, or click to select one</p>
    </div>
  );

  return (
    <div className="popup">
      <h3>Lease Data Extractor</h3>
      <button onClick={logout} className="button">Logout</button>
      {!file && !data && !loading && (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept=".pdf"
          maxFiles={1}
          multiple={false}
          canCancel={false}
          inputContent={<InputContent />}
          styles={{
            dropzone: { width: '100%', height: 200, borderRadius: 10 },
            dropzoneActive: { borderColor: 'green' },
          }}
        />
      )}
      {loading && (
        <div className="loading">
          <Lottie animationData={loadingAnimation} style={{ width: 150, height: 150 }} />
          <p>Extracting data from {file.name}...</p>
          <div className="warning">
            <p>Do not reload the page during data extraction.</p>
          </div>
        </div>
      )}
      {!loading && file && !data && (
        <div className="file-info">
          <p>Selected file: {file.name}</p>
          <button className="button" onClick={extractData}>
            Extract Data
          </button>
        </div>
      )}
      {error && (
        <div className="error">
          <p>{error}</p>
        </div>
      )}
      {data && (
        <div className="data">
          <h4>Extracted Data</h4>
          <div className="data-display">
            <p><strong>Tenant Names:</strong> {data.TenantNames.join(', ')}</p>
            <p><strong>Unit Address:</strong> {data.UnitAddress}</p>
            <p><strong>Square Footage:</strong> {data.SquareFootage}</p>
            <p><strong>Lease Start Date:</strong> {data.LeaseStartDate}</p>
            <p><strong>Lease End Date:</strong> {data.LeaseEndDate}</p>
            <p><strong>Monthly Rent:</strong> {data.MonthlyRent}</p>
          </div>
          <button className="button" onClick={resetUpload}>
            Upload New Lease
          </button>
        </div>
      )}
    </div>
  );
};

export default Popup;
